.headerStyle {
  background-image: linear-gradient(#3372b5, #01498e) !important;
  height: 70px !important;
  font-size: 17px !important;
  align-items: center;
}
.loginButton {
  color: #e35f14 !important;
  border-style: solid !important;
  border-color: #e35f14 !important;
  height: 3.5rem !important;
  width: 10rem !important;
  line-height: 0px !important;
  background-color: white !important;
  font-size: 18px !important;
  border-radius: 5px !important;
  border-width: 1.5px !important;
  font-weight: bolder !important;
  display: flex;
  align-items: center;
}
.emailStyle {
  fill: #e35f14;

  width: 2px;
  height: 2px;
}
.spinnerStyle {
  width: 40px !important;
  height: 40px !important;
  /* left: 200px; */
  margin-left: 252px !important;
  z-index: 999 !important;
  margin-top: 187px !important;
  overflow: visible !important;
  position: fixed !important;
  @media screen and (max-width: 600px) {
    margin-left: 10rem !important;
  }
}
.spinnerStyle:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.labelForIssue {
  font-size: 11px !important;
  white-space: nowrap !important;
  height: 20px !important;
  color: #f64242 !important;
  float: left;
  margin-left: 9rem;
  margin-bottom: -80px;
  @media screen and (max-width: 600px) {
    margin-left: 1rem;
  }
}
.hover {
  display: none;
}

.emailIc {
  width: 30px;
  height: 30px;
  position: absolute !important;
  left: 17%;
  z-index: 1;
  @media screen and (max-width: 600px) {
    left: 2px;
  }
}
.passIc {
  width: 26px;
  height: 26px;
  position: absolute !important;
  left: 17%;
  z-index: 1;
  @media screen and (max-width: 600px) {
    left: 2px;
  }
}

/* linear-gradient(#3372B5,#01498e); */
