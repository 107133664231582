.card {
    width: 85% !important;
    height: 250px !important;
    margin-left: 40px !important;
    @media screen and (max-width: 450px) {
      margin-left: 0 !important;
      width: 100% !important;
    }
  }
  .imageCard {
    width: 100% !important;
    object-fit: cover !important;
    height: 150px !important;
  }
  .topicName {
    margin-top: 30px !important;
    font-size: large !important;
    float: left !important;
    margin-left: 10px !important;
    color:"black"!important;
  }