.headingStyle{
    font-size: 30px !important;
    color: gray !important;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    padding: 10px 0;
    font-weight: bolder;
    margin: 10rem 2rem 2rem!important;
}

.FooterQ {
    @media screen and (max-width: 600px) {
        bottom:0;
        position:fixed;
        width: 100%;
    }
}