.mainpopup{
    height: 600px;
    width: 60%;
    background: white;
    margin: auto;
    padding: 20px;
    @media screen and (max-width: 500px) {
        width: 90%;
        height: fit-content;
    }
}

.iconholder{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.icon:hover{
    cursor: pointer;
}

.mediaholder{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
    height: 500px;
    overflow-y: auto;
    @media screen and (max-width: 500px) {
        height: fit-content;
    }
}
.mediaholder > div > div > div {
    @media screen and (max-width: 500px) {
        height: fit-content!important;
    }
}

.image{
    height: 400px;
    width: 80%;
}