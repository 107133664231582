.content {
  display: flex;
  background-color: #f2f5f9;
}

.content-view {
  background-color: #f2f5f9;
  margin: 1rem 3rem;
  flex: 1;
  margin-left: 25rem;

  overflow: hidden !important;

  p {
    text-align: justify;
  }
}

.programsHeader {
  color: black !important;
  margin-bottom: 30px;
  font-weight: bolder !important;
  margin-bottom: 10px;
}

.card {
  width: 95% !important;
  margin: auto !important;
}

.imageCard {
  width: 100% !important;
  object-fit: cover !important;
  height: 130px !important;
}

.topicNameIndProg {
  margin-top: 15px !important;
  font-size: 15px !important;
  float: left !important;
  font-weight: bold;
  margin-left: 20px !important;
  margin-right: 20px !important;
  color: #3372b5;
}

.progressbar {
  margin-top: 10px;
  width: 85%;
  margin-left: 18px;
  align-self: center;
}

.clock {
  height: 25px !important;
  width: 25px !important;
  margin-right: 10px;
  margin-top: -4px;
}

.contantDiv {
  display: flex;
  flex-wrap: wrap;
}
.contantDiv>div {
  margin-right: 2rem;
  margin-bottom: 2rem;
}