.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
  @media (max-width: 425px) {
    gap: 0.5rem;
  }
  &__icon {
    // height: 2.75rem;
    width: 2.75rem;
  }
}
@media (max-width: 1100px) {
  .header {
    position: relative!important;
    top: -20px!important;
    right: 0!important;
    margin: 0 0 0 auto;
    width: fit-content!important;
  }
}
.dropdown.icon {
  display: none !important;
  color: #c4c4c4 !important;
}
.ui.dropdown .menu .selected.item,
.ui.dropdown.selected:hover {
  background: white;
}
.visible.transition {
  margin-left: -60px !important;
}

.headerButton {
  font-size: 14px!important;
  font-weight: bold!important;
  @media (max-width: 500px) {
    padding: 5px!important;
  }
}