.footerStyle {
  background-image: linear-gradient(#3372b5, #01498e) !important;
  height: 70px !important;
  font-size: 17px !important;
}
.footerContant {
  @media screen and (max-width: 725px) {
    display: block !important;
    width: 100%;
    margin: 0;
    padding: 8px 24px;
    line-height: 0;
    font-size: 15px;
  }
}
