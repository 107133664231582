@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

.maincontainer {
  background: var(--app-background);
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 20px;
  margin: 0px;
}

.header {
  font-family: "nunito";
  font-size: 26px;
  font-weight: 400;
}

.tabholder {
  padding: 20px 0px;
}

.mainsection {
  background-color: #fff;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 0 8px #0000002e;
}

.topsection {
  display: flex;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.topsection>div {
  box-shadow: 0 0 4px #0000002e;
  overflow: auto;
}

.topsection2 {
  display: flex;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
  margin: 4rem 0 1rem;

}

.topsection2>div {
  box-shadow: 0 0 4px #0000002e;
  overflow: auto;
}

.middlesection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0px;
  padding: 20px 0px;
}

.container {
  // height: 120px;
  width: 200px;
  align-items: center;
  text-align: center;
  padding: 10px;
}

.containermiddel {
  height: 120px;
  align-items: center;
  text-align: center;
  padding: 10px;
  display: flex;
  gap: 50px;
}

.title {
  font-family: "nunito";
  font-size: 17px;
  font-weight: 500;
  color: black !important;
  padding: 5px 0px;
}

.icon {
  width: 30px;
  height: 30px;
  align-items: center;
  margin: auto;
  padding-top: 10px;
}

.percent {
  font-family: "nunito";
  font-size: 14px;
  font-weight: 500;
  color: black;
  // padding-bottom: 20px;
}

.middlesection {
  height: 200px;
  padding: 30px 0px;
}

.table {
  padding-top: 20px;
}

.image {
  width: 200px;
  height: 100px;
  border-radius: 10px;
}

.programname:hover {
  cursor: pointer;
  transform: scale(1.01);
}

.field {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  // margin-bottom: -15px;
  gap: 10px;
}

.primaryButton {
  background: var(--button-background);
  border: none;
  border-radius: 5px;
  width: fit-content;
  color: var(--button-text);
  font-family: "nunito";
  font-weight: bold;
  font-size: 16px;
  padding: 4px 10px;
}

.customtable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.customtable th,
.customtable td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.customtable th {
  background-color: rgb(203, 223, 238);
}

.customtable tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}




.imgContainer {
  width: 40%;
  height: 13rem;
  margin-right: 20px;
  overflow: hidden;
}
