form {
    font-weight: 600;
}
li {
    margin: 1.5rem 0rem;
    padding-left: 1.5rem;
}
li > div {
    /* display: flex; */
    display: block;
    justify-content: space-between;
}
b {
    font-weight: 900;
}
th,td{
    font-size: 13px;
}
td > TextArea{
    resize: none;
}
.profile{
    margin: 2rem 0rem;
    border: 1px solid;
    width: 15rem;
    height: 9rem;
}
.headerContainer{
    display: flex;
    justify-content: space-evenly;
}
.spaceBetween{
    display: flex;
    justify-content: space-between;
}
.subHeading {
    background-color: rgb(224 225 226);
    padding: 0.5rem 1rem;
}
.checkboxContent {
    display: flex; 
    justify-content: space-between;
    width: 50%;
}
.inputContainer {
    width: 80%!important;
}
.inputContainer2 {
    width: 35%!important;
}
.inputContainer3 {
    width: 100%!important;
}
.inputContainer4 {
    width: 70%!important;
}
.inputContainer3 > textarea {
    border: 1.5px solid black!important;
    width: 100%!important;
    resize: none;
}
.inputField {
    border: none!important; 
    border-bottom: 1.5px solid black!important;
    border-radius: 0!important;
    padding: 4px!important;
}
.tableInputField {
    border: none!important;
    border-radius: 0!important;
    padding: 7px!important;
    width: 100%;
    border-bottom: 1px solid  gray!important;
}
.tablepField {
    border: none!important;
    border-radius: 0!important;
    padding: 0px!important;
}
.buttonContainer{
    align-items: center;
    text-align: center;
    margin: 2rem;
}
.buttonContainer > button{
    /* background-color: #da251c!important;*/
    color: black!important;
    width: 13rem!important;
}
.mainform  > ol  > li{
    padding: 6px 0 !important;
}
.emailp{
    width: 100%;
    background-color: #fff;
    padding: 5px;
    border-bottom: 1px solid black !important;
}
.filelabel{
    cursor: pointer;
    border: 1px solid #e0e1e2;
    padding: 4px;
    border-radius: 4px;
}
.fileinput{
    width: "15rem"; 
    height: "4rem";
    visibility: hidden;
}
@media only screen and (max-width: 800px) {
    ol{
        padding-inline-start: 18px;
    }
    li{
        padding-left: 1rem;
    }
    li > div {
        display: block;
    }
    .profile{
        margin: auto;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    .checkboxContent {
        width: 100%!important;
    }
    .headerContainer{
        display: block!important;
    }
    .spaceBetween > label{
        display: block!important;
    }
    .spaceBetween{
        display: block!important;
    }
    .inputContainer{
        width: 100%!important;
    }
    .inputContainer2{
        width: 100%!important;
        margin-bottom: 1rem;
    }
    .inputContainer3{
        width: 100%!important;
    }
    .inputContainer4{
        width: 100%!important;
    }
}