//Begin template
body {
  background: #eee;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  box-sizing: border-box;
}

* {
  box-sizing: inherit;
}

.container {
  
  margin: 0 auto;
}

//End template
.disscussionInput {
  width: 100%;
  padding: 15px;
  border-radius:6px ;
  height: 100px;
  border-style: groove;
  white-space: normal;
  box-shadow: 0 0 11px rgba($color: #000000, $alpha: 0.3);
  font-size: 14px;
}
.disscussionInputPopup {
  width: 100%;
  padding: 15px;
  border-radius:6px ;
  height: 150px !important;
  border-style: groove;
  margin-top: 10%;
  margin-bottom: 2rem;
}
.buttonPost{
    position: absolute;
    right: 20px;
    top: 50px;
    color: #fff !important;
    //background-color: #3f51b5 !important;
    border-radius: 8px !important;
    font-size: 18px;
    height: 30px;
    line-height: 7px;
    // @media screen and (max-width: 450px) {
    //   margin-top: 1rem !important;
    // }
}
.buttonPostSecond{
  position: revert !important;
  font-size: 18px;
  margin: 0!important;
  height: 30px;
  line-height: 7px;
}
.buttonCancel{
    background-color: white !important;
    border-radius: 8px !important;
    font-size: 18px;
    border: 2px solid #3372B5;
    font-weight: bold;
    padding: 0px 10px;
    cursor: pointer;
}
.embedsubmitfield {
  position: relative;
  
  
  input {
    width: 100%;
    padding: 15px;
    border-radius:6px ;
    height: 120px;
    border-style: groove;
  }
  button {
    position: absolute;
    right: 20px;
    top: 55px;
    font-weight: bold;
    margin-top: 20px;
    color: #fff !important;
  background-color: #3372B5 !important;
  border-radius: 8px !important;

    
    
    //Only styling from here, you can discard this
    -webkit-appearance: none;
    -moz-appearance: none;
    
    border: none;
    background: #ededed;
    border-radius: 3px;
    padding: 10px;
    
    transition: all .2s;
    
    
  }
}
.messageContent{
    font-size: large !important;
}
.replyIcon{
    width: 26px;
    height: 26px;
}
.deleteIcon{
  width: 20px;
    height: 20px;
    margin-top:45px ;
    margin-left: -20px;
}
.deleteIconSub{
  width: 20px;
    height: 20px;
}
