.program {
  display: flex;
  border-radius: 5px;
  box-shadow: 0 0 11px rgba($color: #000000, $alpha: 0.3);
  margin: 1rem;
  margin-top: 50px;
  @media screen and (max-width: 1000px) {
    display: block;
  }

  &__img {
    flex: 0 0 24%;
    background-color: rgb(255, 255, 255);
    border-radius: 5px 0 0 5px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @media screen and (max-width: 1000px) {
      height: 20rem;
    }
  }
  &__details {
    flex: 1;
    background-color: #fff;
    padding: 2rem;
    border-radius: 0 5px 5px 0;
    .h1 {
      text-align: left;
    }
  }
  .individual-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 70%;
    margin-bottom: 1rem;

    p:last-child {
      text-align: left;
    }
  }
  .program-info {
    flex: 0 0 70%;
    align-items: center;
    padding-right: 2rem;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 30%;
      height: 100%;
      width: 0.5px;
      background-color: #01498e;

      transition: transform 0.2s, width 0.4s cubic-bezier(1, 0, 0, 1) 0.2s,
        background-color 0.1s;
    }
    & .individual-info:last-child {
      margin-top: 1rem;
    }
  }
  .time-info {
    flex: 1;
    justify-content: center;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: var(--para-size-small);
  }
  .program_info__container {
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    // width:90%;
    margin-left: 20px;
    @media screen and (max-width: 500px) {
      height: 5rem;
    }
  }
  .width {
    width: 65%;
    margin-top: 1rem;
  }
}

.overviewIcon {
  width: 60px !important;
  height: 60px !important;
  fill: orange !important;
  @media screen and (max-width: 500px) {
    margin-right: 1rem;
  }
}

.overviewIconDis {
  width: 60px !important;
  height: 60px !important;
  fill: gray !important;
}

.approvedIcon {
  width: 70px !important;
  height: 70px !important;
  fill: green !important;
  @media screen and (max-width: 500px) {
    margin-right: 2rem;
  }
}

.approvedIconDis {
  width: 70px !important;
  height: 70px !important;
  fill: gray !important;
  @media screen and (max-width: 500px) {
    margin-right: 2rem;
  }
}
.fillYourDetailsIcon {
  width: 80px;
  height: 80px;
  fill: blue !important;
}
.fillYourDetailsIconDis {
  width: 80px;
  height: 80px;
  fill: black !important;
}
.underReviewIcon {
  width: 25px !important;
  height: 25px !important;
  fill: orangered !important;
}
.hr_line {
  width: 50%;
  position: relative;
  margin: 15px;
  border-bottom: 1px solid #000;
  @media screen and (max-width: 500px) {
    margin: 2px;
  }
}

.openButton {
  margin-left: 80px;
  white-space: nowrap;
  cursor: pointer;
  margin-right: 50px;
  text-decoration: underline;
  font-size: medium;
  @media screen and (max-width: 600px) {
    margin-right: 0;
    margin-left: 10px;
  }
}

.firstFlow {
  margin-top: 80px;
  margin-right: -8px;
  margin-left: -55px;
  width: 50px;
  white-space: nowrap;
  font-size: small;
  @media screen and (max-width: 500px) {
    margin-top: 40px;
    font-size: 1rem;
    width: 90px;
  }
}
.secondFlow {
  margin-top: 80px;
  margin-right: -20px;
  margin-left: -60px;
  white-space: nowrap;
  font-size: small;
  @media screen and (max-width: 500px) {
    margin-top: 40px;
    margin-left: -50px;
    font-size: 1rem;
  }
}
.thirdFlow {
  margin-top: 80px;
  margin-right: -8px;
  margin-left: -51px;
  width: 50px;
  white-space: nowrap;
  font-size: small;
  @media screen and (max-width: 500px) {
    margin-top: 40px;
    font-size: 1rem;
  }
}